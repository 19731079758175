@media (min-width: 1025px) {
    .h-custom {
        height: 100vh !important;
    }
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
}

.card-registration .select-arrow {
    top: 13px;
}

.gradient-custom-2 {
    /* fallback for old browsers */
    background: #a1c4fd;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1))
}

.bg-indigo {
background-color: #0D03C3;
}

@media (min-width: 992px) {
    .card-registration-2 .bg-indigo {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}

@media (max-width: 991px) {
    .card-registration-2 .bg-indigo {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}

.bgimg{
    background-image: url('../../../public/images/shb.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
  
}
.bgimg2{
    background-image: url('../../../public/images/shb.png');
        background-repeat: no-repeat;
        background-size:cover;
    background-position: left;
        
}

.su{
width: 100px;
    height: 50px;
    border-radius: 10px;
    background: #054B49;
  
        font-size: 20px;
        font-weight: 800;
     color: white;
        letter-spacing: 0em;
        text-align: center;
}
.os{

        font-size: 20px;
        font-weight: 800;
}
.li{
width: 90px;
    height: 50px;
   border-radius: 0px;
   color: #0F726E;

    font-size: 20px;
    font-weight: 800;
  
    letter-spacing: 0em;
    text-align: center;
}
.li:hover{
    border: 0px solid #0F726E;
    color: #0F726E
}
.star{
    color: #0F726E
}
/* input[type=text]:focus {
    border: 2px solid #0F726E;
}

input[type=email]:focus {
    border: 2px solid #0F726E;
}
input[type=number]:focus {
    border: 2px solid #0F726E;
} */
.rerow{
    margin-top: 5%;
box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);;
}
.phoneinput{
    max-width: 150px;
}
