main{

background-color: #05050b;
    border-right: 0 solid #05050b;
    bottom: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    left: 0;
    position: fixed;
    top: 0;
    transition: all .2s ease-in-out;
    z-index: 1035;
    background-color: #05050b;
        border-right: 0 solid #05050b;
        bottom: 0;
        color: #fff;
        display: flex;
        flex-direction: column;
        left: 0;
        position: fixed;
        top: 0;
        transition: all .2s ease-in-out;
        z-index: 1035;
}
.headside{
        position: fixed;
            top: 0;
            transition: all .2s ease-in-out;
            z-index: 1035;
}
.sidebar{
  
    position: sticky;
 
    bottom: 0px;
background: #05050b;
        color: rgb(255, 255, 255);
      
 
       
        transition: all 0.2s;
      z-index: 1;

        top: 0;
        bottom: 0px;
       height: 100vh;
    border-radius: 1rem;
       
        margin: 1rem;
        overflow-y: auto;
        background-color: #2a4964;
            border-right: 0 solid #05050b;
            bottom: 0;
            color: #fff;
           
    
}
.lefticon{
    font-size: 37px;
}
.righticon{
    font-size: 35px;
    margin-left: 13%;
}
.top_section{

    padding-top:5px ;
   
}

.logo{
    font-size: 30px;
    color: #fff;
}
.bars{
    display: flex;
    font-size: 50px;
    margin-left: 100%;
}
.link{
    display: flex;
    color: rgb(255, 255, 255);
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.2s;
    text-decoration: none;
}
.link:hover{
background-color: #31587b;
    color: #e7eef8;
    transition: all 0.2s;
}
.active{
background-color: #31587b;
    color: #e7eef8;
}
.icon, .link_text{
    font-size: 20px;
}
.container_side{display: flex;}
.logoutSideBtn{
   margin-top: 100%;
}
.logo{
    cursor: pointer;
}


