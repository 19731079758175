/* form {
    margin: 30px auto 0;
    max-width: 400px; 
 
} */

.front {
    position: relative;
    transform: rotateY(0deg);
    background-color: red;
}

.back {
    position: relative;
    transform: rotateY(180deg);
    background-color: yellow;
}

.rccs__card {
    height: 182.873px;
    margin-top: 10;
    margin-bottom: 10;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    width: 290px;

    /* background: linear-gradient(
   25deg
, #986, #234); */
}

.front1 {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: red;
}

.back1 {
    transform: rotateY(360deg);
    margin-bottom: 30px !important;
    background-color: yellow;
}

.App-cards-list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.App-cards-list>* {
    transform: scale(0.8);
    margin-bottom: 30px !important;
}

.back3 {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    background-image: url("../../images/logo-removebg-preview.png");
}

/* rccs-signature-background: repeating-linear-gradient(
  0.1deg,
  #fff 20%,
  #fff 40%,
  #fea 40%,
  #fea 44%,
  #fff 44%
);
rccs-default-background: linear-gradient(25deg, #d32121, #178a63);
rccs-unknown-background: linear-gradient(25deg, rgb(160, 18, 101), #999);
rccs-background-transition: 0.5s;
rccs-animate-background: true; */

.rccs__card--unknown>div {
    /* background-image: url("../../images/logo-removebg-preview.png");
  background-repeat: no-repeat;
    background-size: contain; */
    margin-top: 10%;
    background-color: linear-gradient(25deg, rgb(160, 18, 101), #999);
}
.example {
  
  
   
    text-align: center;
 
    border-radius: 4px;
}
.exp-wrapper {
    position: relative;
    border: 1px solid #aaa;
    display: flex;
    width: 300px;
    justify-content: space-around;
    height: 36px;
    line-height: 36px;
    font-size: 24px;
}

.exp-wrapper:after {
    content: '/';
    position: absolute;
    left: 50%;
    margin-left: -4px;
    color: #aaa;
}

input.exp {
    float: left;
    font-family: monospace;
    border: 0;
    width: 18px;
    outline: none;
    appearance: none;
    font-size: 14px;
}