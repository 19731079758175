.analysispage{
        background-color: #111;
       min-height: 100vh;
        flex: 1;
      

         
}
.anacard{
        flex: 1;
            margin: 20px;
            padding: 10px 40px;
            border-radius: 8px;
            background-color: #111;
            color: #fff;
            cursor: pointer;
            -webkit-box-shadow: 0px 0px 15px -8px rgb(209 208 208 / 75%);
            box-shadow: 0px 0px 15px -8px rgb(190 190 190 / 75%);
}