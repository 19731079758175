.code{
    min-height: 505px;
}
.app__container {
    min-height: calc(100vh);
    padding: 60px;
    background: linear-gradient(to bottom, #a90329 0%, #8f0222 44%, #6d0019 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
  
  .app__random-no-container {
    text-align: center;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.5);
  }