.routes{

}

.routes{
  width: 100%;
}
*{margin: 0px;padding: 0px;}

/* .App{
  background: -webkit-linear-gradient(left, #3931af, #00c6ff);
} */
body{  background: white;overflow-x: hidden;}

.sideP{
  height: 100%;
}