footer {
        
background: linear-gradient(180deg, #0F726E 0%, #054B49 0.01%, #17918C 97.92%);
}

.footer_text{
text-align: left;
color: #ccc;
font-style: italic;
font-size: 13px;
}

.link_footer ul li a{color: #ccc;}

.link_footer h5{color: #fff;}

.wah{

        font-style: normal;
        font-weight: 800;
   
        line-height: 67px;
        /* or 112% */
    
        text-align: center;
}
.gsf{
    width: 227.81px;
        height: 50.04px;
       

        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        /* or 150% */
    
        text-align: center;
        letter-spacing: -0.02em;
    
      
        background: #FFFFFF;
        border-radius: 500px;
        color: #0F726E;
}
