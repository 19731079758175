
#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] { font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; }

#contact {background: #fff;padding: 25px;;margin: 50px auto;}

#contact h3 {
	color:#1e79d6;
	display: block;
	font-size: 30px;
	font-weight: 400;
    text-align: center;
}

#contact h4 {margin:5px 0 15px;display:block;font-size:13px;}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
	width: 100%;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
	width:100%;
	border:1px solid #CCC;
	background:#FFF;
	margin:0 0 5px;
	padding:10px;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	-webkit-transition:border-color 0.3s ease-in-out;
	-moz-transition:border-color 0.3s ease-in-out;
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {height:100px;max-width:100%;resize:none;}

#contact button[type="submit"] {
	cursor:pointer;
	width:100%;
	border:none;
	background:#1e79d6;
	color:#FFF;
	margin:0 0 5px;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#1f7ddc;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {outline:0;border:1px solid #999;}

input[type=checkbox] {display:none;}

.checkwallet {display: block;margin: 10px auto;margin-left: 3px;}

.checkwallet:hover {cursor: pointer;}

input[type=checkbox] + .checkwallet:before {
  content: '';
  display: inline-block;
  border: 2px solid #0F81D5;
  margin-right: 10px;
  height: 12px;
  width: 12px;
  vertical-align: middle;
  transition: all .1s linear;
}

input[type=checkbox]:checked + .checkwallet:before {
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-top-width: 0px;
  border-right-width: 0px;
	transform: rotate(-45deg) translate(2px, -2px);
    height: 10px;
    width: 16px;
}
.note_wallet{color: red;}

.amount_wallet{
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #222222c2;
    margin-top: 0;
    margin-bottom: 15px;
}
.amount_wallet span{color:#0F81D5;}

.euro_amount{margin-left: 20px;}

.dollar_amount{margin-left: 7px;}

.btn_chaceout{float: right;clear: both;}

.btn_link_color{color: #fff;}

.walletContainer{
	min-height: 100vh;
	background-color: #F8FFFE;
}
.balanceCard{
	background: linear-gradient(180deg, #2EB9AA 0%, #0F1419 100%);
		border-radius: 30px;
		
			height: 273.28px;
}
.balancetext{
	font-weight: 700;
	font-size: 60px;
}

.sendbtn{
	background: linear-gradient(119.15deg, #2EB9AA -13.54%, #292F3C 118.09%);
	color: white;
	font-size: 25px;
}
.wcredit {

	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	height: 100px;
	


}
.wpaypal {

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100px;
	


}
.wbank {
	
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 100px;
	


}
.wcrypto {

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 100px;



}
.cryptocolor{
	color: #907744;
}
