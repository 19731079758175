
.form-container {
    margin-bottom: 50%;
    box-shadow: 1px 1px 5px rgba(238, 0, 0, 0.1);
    display: table;
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 2px 2px 20px 1px var(--bs-blue);
    color: #fff;
    transform: translateZ(511px);
background: linear-gradient(180deg, #0F726E 0%, #054B49 0.01%, #17918C 97.92%);
    padding: 50px;
    margin-bottom: 70%;
}
.loginhere:hover{
   transform: translateZ(511px);
}
.shop{
    min-height: 700px;
    background-color: white;
}
.product{
    margin-bottom: 15%;
}