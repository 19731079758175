.input-container {
  display: flex;
  align-items: center;
}

.input-container span {
 
 width:5%;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(34, 81, 199, 0.311);
  cursor: pointer;
}

.input-container input {
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  border: none;
}
