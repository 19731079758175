.childDashboard {
  display: grid;
  margin-top: 10%;
 

  align-items: stretch;
  justify-items: center;
}
.mainDashboard {
height:100vh;
background: #ffffff;
  text-align: center;
 
}
.monthsdropdown{
  --bs-btn-color: #4d69fa;
    --bs-btn-bg: #edf0ff;
    --bs-btn-border-color: #edf0ff;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4d69fa;
    --bs-btn-hover-border-color: #4d69fa;
    --bs-btn-focus-shadow-rgb: 213, 220, 254;
    --bs-btn-active-color: #e7eef8;
    --bs-btn-active-bg: #5f78fb;
    --bs-btn-active-border-color: #5f78fb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #edf0ff;
    --bs-btn-disabled-border-color: #edf0ff;
    --bs-btn-border-radius: 1rem;
}
.yellow{
color: #de8912 !important;
}
.primaryCard{
  --bs-card-border-color: rgba(222, 226, 230, .5);
  background-color: #dad7f4 !important; 
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
    margin-bottom: calc(1.275rem + 0.3vw);
    --bs-card-border-color: rgba(222, 226, 230, .5);
  
    --bs-card-border-radius: 2rem;
}
.headCardschart{
  --bs-card-border-color: rgba(222, 226, 230, .5);
    background-color: #fff3d4 !important;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
    margin-bottom: calc(1.275rem + 0.3vw);
    --bs-card-border-color: rgba(222, 226, 230, .5);
  
    --bs-card-border-radius: 2rem;
}
.OrdersSummary {
  --bs-card-border-color: rgba(222, 226, 230, .5);
  background-color: #ffe8ef !important;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
  margin-bottom: calc(1.275rem + 0.3vw);
  --bs-card-border-color: rgba(222, 226, 230, .5);

  --bs-card-border-radius: 2rem;
}
.OrdersSummary2 {
  --bs-card-border-color: rgba(222, 226, 230, .5);
  background-color: #0e217356 !important;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
  margin-bottom: calc(1.275rem + 0.3vw);
  --bs-card-border-color: rgba(222, 226, 230, .5);

  --bs-card-border-radius: 2rem;
}
.OrdersSummary3 {
  --bs-card-border-color: rgba(222, 226, 230, .5);
  background-color: #0e467356 !important;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
  margin-bottom: calc(1.275rem + 0.3vw);
  --bs-card-border-color: rgba(222, 226, 230, .5);

  --bs-card-border-radius: 2rem;
}
.headCards{
  --bs-card-border-color: rgba(222, 226, 230, .5);
    background-color: #fff;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
    margin-bottom: calc(1.275rem + 0.3vw);  
    --bs-card-border-color: rgba(222, 226, 230, .5);
    
    --bs-card-border-radius: 2rem;
}
.orangeCard{
background:linear-gradient(119.15deg, #2EB9AA -13.54%, #292F3C 118.09%);
  box-shadow: 9.71163px 11.654px 77.693px rgba(84, 135, 183, 0.08);
  border-radius: 24.7205px;
}
.seeinfo{
  background: #CECECE;
    border-radius: 16px;

     
      color: #d0be24;
}

.admindashboard{
  min-height: 100vh;
}

