/* .main-content{
	width: 60%;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 40%);
  
  
	
    background-color: unset!important;
  
}
.company__info{
	background-color:#05b9f8;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
}
.fa-android{
	font-size:3em;
}
@media screen and (max-width: 640px) {
	.main-content{width: 90%;}
	.company__info{
		display: none;
	}
	.login_form{
		border-top-left-radius:20px;
		border-bottom-left-radius:20px;
	}
}
@media screen and (min-width: 642px) and (max-width:800px){
	.main-content{width: 70%;}
}
.row > h2{
	color:#05b9f8;
}
.login_form{
	background-color: rgb(0, 0, 0);
	border-top-right-radius:20px;
	border-bottom-right-radius:20px;
	border-top:1px solid #ccc;
	border-right:1px solid #ccc;
    padding: 20px;
}
form{
	padding: 0 2em;
}
.form__input{
	width: 100%;
	border:0px solid transparent;
	border-radius: 0;
	border-bottom: 1px solid #aaa;
	padding: 1em .5em .5em;
	padding-left: 2em;
	outline:none;
	margin:1.5em auto;
	transition: all .5s ease;
}
.form__input:focus{
	border-bottom-color:#05b9f8;
	box-shadow: 0 0 5px rgba(0,80,80,.4); 
	border-radius: 4px;
}
.login_forms button:hover, .login_forms button:focus{
background: linear-gradient(180deg, #96BCBB 0%, #D5EEED 100%);
	border-radius: 40px;
	color:#fff!important;
}
.login_forms button{
    transition: all .5s ease!important;
    width: 70%!important;
    border-radius: 30px!important;
    color: #1986dd!important;
    font-weight: 600!important;
background: linear-gradient(180deg, #96BCBB 0%, #D5EEED 100%);
	border-radius: 40px;
    border: 1px solid #1986dd!important;
    margin: 15px auto!important;
} */


.loginpage{
min-height: 50vh;
}
.loginform{
background: #F0F0F0;
	border-radius: 40px;
		border-radius: 40px;

	
}
.loginbtn{
	background: #0F726E;
		border-radius: 5px;

			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 19px;
			/* identical to box height */
		width: 347px;
			height: 50px;
		
			color: #FFFFFF;
}
.loginbtn:hover {
	background: #0f725b;
	border-radius: 5px;

	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 19px;
	/* identical to box height */
	width: 347px;
	height: 50px;

	color: #FFFFFF;
}
.empas{
margin-bottom: 1%;

		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		/* identical to box height */
	
	
		color: #000000;
}
.emailinput{
	border: 1px solid #000000;
		border-radius: 5px;
		background-color:  #0f726f00;
}
.passinput{
		border: 1px solid #000000;
	background: #0f726f00;
		border-radius: 5px;
}