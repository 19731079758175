.Taple_project{width: 70%;margin: 20px auto;background-color: #fff;}

.title_page{margin: 50px;color: #fff;text-align: center;}

.note_cacheout{color:#626262;}
.sp{
    min-height: 100vh;
}

.servicceProvidersCard{
    --bs-card-border-color: rgba(222, 226, 230, .5);
        background-color: #fff;
        background-color: var(--bs-card-bg);
        border: var(--bs-card-border-width) solid var(--bs-card-border-color);
        box-shadow: 0 1.6rem 3rem rgb(0 0 0 / 10%);
        margin-bottom: calc(1.275rem + 0.3vw);
        --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
            --bs-card-title-spacer-y: 0.5rem;
            --bs-card-border-width: 0;
            --bs-card-border-color: #dee2e6;
            --bs-card-border-radius: 2rem;
            --bs-card-box-shadow: 0 1.6rem 3remrgba(0, 0, 0, .1);
            --bs-card-inner-border-radius: 2rem;
            --bs-card-cap-padding-y: 0.75rem;
            --bs-card-cap-padding-x: 1.5rem;
            --bs-card-cap-bg: rgba(0, 0, 0, .03);
            --bs-card-cap-color: ;
            --bs-card-height: ;
            --bs-card-color: ;
            --bs-card-bg: #fff;
            --bs-card-img-overlay-padding: 1rem;
            --bs-card-group-margin: 0.75rem;
            word-wrap: break-word;
            background-clip: initial;
            border-radius: var(--bs-card-border-radius);
            box-shadow: var(--bs-card-box-shadow);
            display: flex;
            flex-direction: column;
            height: var(--bs-card-height);
            min-width: 0;
            position: relative;
}
.card-header{
        border-bottom-width: 1px;
}
.btn-light-info{
        --bs-btn-color: #4d69fa;
            --bs-btn-bg: #edf0ff;
            --bs-btn-border-color: #edf0ff;
            --bs-btn-hover-color: #fff;
            --bs-btn-hover-bg: #4d69fa;
            --bs-btn-hover-border-color: #4d69fa;
            --bs-btn-focus-shadow-rgb: 213, 220, 254;
            --bs-btn-active-color: #e7eef8;
            --bs-btn-active-bg: #5f78fb;
            --bs-btn-active-border-color: #5f78fb;
            --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
            --bs-btn-disabled-color: #000;
            --bs-btn-disabled-bg: #edf0ff;
            --bs-btn-disabled-border-color: #edf0ff;
}
.serviceTable{
    
    --bs-table-color: #323232;
        --bs-table-border-color: var(--bs-border-color);
        --bs-table-bg: transparent;
        --bs-table-striped-color: #323232;
        --bs-table-striped-bg: rgba(0, 0, 0, .038);
        --bs-table-active-color: #323232;
        --bs-table-active-bg: rgba(0, 0, 0, .1);
        --bs-table-hover-color: #323232;
        --bs-table-hover-bg: rgba(0, 0, 0, .075);
        border-color: var(--bs-table-border-color);
        color: var(--bs-table-color);
        
}
.eyeOutline {
    color: blue;
    font-size: 18px;
    cursor: pointer;
}
.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: rgb(255, 254, 254);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}