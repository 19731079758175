/* .register{

    width: 100%;
}
.imgdiv{
   
  
 z-index: 1;
        height: 200px;
        width: 100px;
        border-radius: 150px 0 0 150px ;
        background-color: #0D03C3;
}
.imgbg{
    z-index: 1;
    position: relative;
    top:-35px;
   left:50px
}
.imgdiv2 {


z-index: 1;
overflow: hidden;
    height: 160px;
    width: 90px;
    margin-left: 30px;
    border-radius:  0 150px 150px 0 ;
    background-color: #1a181d;
} */
#sbtn{
background: #0F726E;
    border-radius: 6px;
    color: white;
}
.rs{

}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
}
.register-left input{
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;

    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
}


@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.register-left p{
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}
.register .register-form{
    padding: 10%;
   
}


.register .nav-tabs{
    margin-top: 3%;
    border: none;
    background: #0062cc;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}
.register .nav-tabs .nav-link{
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
    border: none;
}
.register .nav-tabs .nav-link.active{
   
    color: #0062cc;
    border: 2px solid #0062cc;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.register-heading{
    
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
}
