
.fortest {display: grid;justify-content: center;margin-top: 3rem;}

.maincounter {width: 27rem;background-color: aliceblue;}

.body {display: flex;justify-content: space-between;}

.body h3 {margin-left: 1rem;}

#status {margin-right: 2.5rem;}

.body h4 {margin-right: 1rem;}

.body span {font-size: 15px;}

.fotter {display: flex;justify-content: space-between;}

.fotter button {background-color: rgba(0, 255, 255, 0.101);border-style: none;margin-right: 1rem;}

.fotter p {margin-left: 1rem;}
.historypage{
  min-height: 100vh;
}
.customerspage{
  min-height: 100vh;
}
@media only screen and (max-width: 427px) {
  .fortest {
    display: grid;
    justify-content: space-around;
  }
}

.show{display: block;}

.hide{display: none;}

.title_page{margin: 50px;color: #fff;text-align: center;}

.show{
  display: block;
}
.hide{
  display: none;
}

.statussuccess{
  background-color: rgba(20, 240, 31, 0.432);
}
.statusnotcompleted{
  background-color: rgba(75, 80, 75, 0.432);
}
.statusonprocess{
  background-color: rgba(255, 230, 0, 0.486);
}
.rejected{
  background-color: rgba(219, 20, 20, 0.24);

}